@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.topBar {
  align-items: center;
  background-color: none;
  display: flex;
  padding: 15px 16px;
  position: relative;
  z-index: $gl-z-index-top-bar;
  column-gap: 16px;

  @include mobile {
    padding-right: 65px;
  }

  &.organization {
    @media (max-width: $gl-breakpoint-webflow-mobile) {
      display: none;
    }

    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      display: flex;
    }
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: flex;
    padding: 12px 0 15px;
    row-gap: 8px;
    flex-direction: column;

    &.newApp {
      padding: 0 0 15px;
      gap: 12px;
    }
  }
}

.topBarGradient {
  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    position: absolute;
    height: 80px;
    width: 100%;
    top: 0;
    background: linear-gradient(
      180deg,
      #f2f0ee 54.93%,
      rgba(246, 246, 246, 0) 88%
    );

    &.withCart {
      height: 150px;
      background: linear-gradient(
        180deg,
        #f2f0ee 82.93%,
        rgba(246, 246, 246, 0) 100%
      );
    }

    &.newApp {
      height: 132px;
      background: linear-gradient(
        180deg,
        #f2f0ee 82.93%,
        rgba(246, 246, 246, 0) 100%
      );
    }
  }
}

.mainHeader {
  z-index: 999;
  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    position: sticky;
    top: -1.1px;
  }
}

.topBarManage {
  height: 30px;
}

.appIconContainer {
  display: flex;
  height: 42px;
  width: 42px;
  border-radius: 8px;
  object-fit: contain;
  box-shadow: 2px 2px 16px 0px rgba(30, 73, 110, 0.07);
  background-color: $clr-white-high;

  @include mobile {
    border-radius: 4px;
    height: 32px;
    width: 32px;
  }
}

.appName {
  @include font-size($gl-font-size-3xl);
  color: $clr-primary;
  font-weight: $gl-font-weight-bold;
  line-height: 1.25;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.appSaveInfo {
  @include font-size($gl-font-size-2xs);
  font-weight: $gl-font-weight-regular;
  color: #b5b3b1;
  line-height: 15px;
  text-align: center;
  min-width: fit-content;

  @include mobile {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  min-width: fit-content;
  column-gap: 16px;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    margin: 0 auto;
    column-gap: 8px;
  }
}

.button {
  min-width: 160px;

  @include mobile {
    min-width: 100px;
  }

  @include phone {
    min-width: 87px;
  }
}

.menuButton {
  height: 45px;
  width: 45px;
  padding: 0 !important;
}

.menuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle {
  display: none !important;

  @include mobile {
    position: absolute;
    right: 0;
    bottom: 15px;
    align-items: center !important;
    background-color: #4b4b4b !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    color: $clr-white;
    display: flex !important;
    padding: 10.5px 5px 10.5px 9px !important;
    z-index: $gl-z-index-overlay;

    &:hover {
      color: $clr-white;
    }
  }
}

.toggleIcon {
  flex-shrink: 0;
}

.toggleChevron {
  flex-shrink: 0;
  margin-right: 5px;
  color: $clr-primary-200;
}

.title {
  display: flex;
  column-gap: 12px;
  align-items: center;
  overflow: hidden;
  min-width: 260px;
  max-width: 100%;
  width: 100%;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: none;
  }
}

.titlePhone {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    background-color: $clr-background-light;
    font-size: $gl-font-size-3xl;
    padding: 16px 24px 0;
    justify-content: center;
    display: flex;
    column-gap: 12px;
    align-items: center;
    overflow: hidden;
    min-width: 260px;
    max-width: 100%;
    width: 100%;
  }
}

.sectionTitle {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
  }
}

.organizationTitle {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    @include font-size($gl-font-size-xl);
    color: $clr-primary-500;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    font-weight: $gl-font-weight-bold;
  }
}

.items {
  display: flex;
  align-items: center;
  column-gap: 12px;
  min-width: fit-content;
}

.togglePhone {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    position: absolute;
    left: 0;
    bottom: 15px;
    align-items: center !important;
    background-color: #4b4b4b !important;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    color: $clr-white;
    display: flex !important;
    padding: 10.5px 5px 10.5px 9px !important;
    z-index: $gl-z-index-overlay;
  }
}

.toggleChevronRight {
  color: $clr-primary-200;
}

.deleted {
  font-style: italic;
}

.pill {
  margin: auto;
  min-width: auto !important;
}

.discardButton {
  background-color: $clr-white;
}
