@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.main {
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 0 0 115px;
  position: relative;
  width: 100%;
  background-color: $clr-white-high;
  margin-right: 16px;
  border-radius: 0 16px 16px 0;
  box-shadow: 3px 2px 16px 0px rgba(30, 73, 110, 0.07);
  min-width: 795px;

  // Override style from application stylesheet.
  min-height: auto;
  height: calc(100% - 16px);

  // Scrollbar
  @include styled-scrollbar(8px);

  @include mobile {
    min-width: 0;
  }

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    border-radius: 16px;
    min-width: auto;
    margin: 0 16px 16px;

    // Scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.dashboard {
    margin-left: 16px;
    background-color: transparent;
    border-radius: 16px;
    box-shadow: none;
    padding: 0;
    min-width: 1000px;
    margin-right: 0px;
    min-height: 50px;

    @include mobile {
      min-width: 0;
    }

    &::-webkit-scrollbar-track {
      margin-block: 0;
    }

    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      border-radius: 16px 16px 0 0;
      margin-right: 16px;
    }
  }

  &.organization {
    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      padding: 0;
      margin: 0 16px 115px;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
