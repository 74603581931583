@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.modal {
  background-color: $clr-white;
  border-radius: 16px;
  max-height: calc(100% - 32px);
  max-width: 445px;
  width: calc(100% - 32px);
}

.modalHeader {
  flex-shrink: 0;
  min-height: 84px !important;
  padding: 16px 16px 16px 24px !important;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow: auto;
  max-height: 400px;
  position: relative;
}

.modalFooter {
  border-top: 1px solid #d1d4e4;
  gap: 16px !important;
  display: flex;
  flex-shrink: 0;
  padding: 24px !important;
  justify-content: flex-end;
}

.modalFooterButton {
  // Fix overlapping loader
  min-width: 120px;
  padding: 0 30px;
}

.headerTitle {
  font-weight: $gl-font-weight-medium !important;
}

.description {
  @include font-size($gl-font-size-base);
  color: $clr-black-medium;
  margin: 0;
}
