@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.pill {
  font-size: 10px;
  letter-spacing: 1.5px;
  align-items: center;
  border-radius: 12px;
  line-height: 12px;
  display: inline-flex;
  font-weight: $gl-font-weight-semibold;
  padding: 4px 8px;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  min-width: 108px;

  &.danger {
    background-color: $clr-danger;
    color: $clr-white;
  }

  &.disabled {
    background-color: #0000001a;
    color: $clr-black-high;
  }

  &.enabled {
    background-color: #00e67626;
    color: #018135;
  }

  &.info {
    background-color: #ddefff;
    color: #3090e3;
  }

  &.primary {
    background-color: #62aae826;
    color: $clr-primary-400;
  }

  &.warning {
    background-color: $clr-warning;
    color: $clr-white;
  }
}
