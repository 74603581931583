@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.pricingSummaryFooter {
  background-color: $clr-white;
  border-bottom: 1px solid #f2f2f7;
  top: 0;
  column-gap: 16px;
  display: flex;
  row-gap: 10px;
  padding: 16px 16px 16px;
  position: sticky;
  flex-wrap: wrap;
  z-index: 100;

  &.appBuilder {
    top: 0px;
    border-bottom: 1px solid #c1c9d3;
  }
}

.pricingSummaryFooterItems {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  justify-content: flex-end;
}

.pricingSummaryFooterPrice {
  @include font-size($gl-font-size-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $clr-primary;
  font-weight: $gl-font-weight-bold;
  min-width: 50px;
  width: fit-content;

  .total {
    font-weight: $gl-font-weight-regular;
  }
}

.pricingSummaryFooterButton {
  height: 48px;
  min-width: 90px;
  padding: 0;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;

  &.icon {
    min-width: 48px;
    width: 48px;
    flex: 0;
  }

  &.checkout {
    color: $clr-black;
  }
}

.none {
  display: none;
}

.container {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  border-radius: 0;
}

.content {
  height: 100%;
  width: 100%;
}

.header {
  padding: 0;
}

.back {
  @include font-size($gl-font-size-xs);
  display: flex;
  gap: 10px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin: 0 16px 16px;
  font-weight: 700;
  color: $clr-primary;
}

.costSummary {
  padding: 0 20px;
  width: 100%;
}

.icon {
  @include mobile {
    padding-top: 4px;
  }
}
