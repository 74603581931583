@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
$padding-before-icon-x: 10px;

.selectContainer {
  .select {
    flex: 1;
    font-weight: $gl-font-weight-medium;

    :global(.gnSelect__indicator) {
      padding-left: 0;
      padding-right: 15px;
    }

    :global(.gnSelect__option) {
      padding: $gl-select-padding-y $padding-before-icon-x;
    }

    :global(.gnSelect__value-container) {
      padding-left: $padding-before-icon-x;
    }
  }

  &.primary {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.withIcon {
      align-items: center;
      background-color: $gl-select-primary-background-color;
      border-radius: $gl-select-primary-border-radius;
      border: $gl-select-primary-border;
      display: flex;

      :global(.gnSelect__control) {
        background: none;
        border: none;
      }

      :global(.gnSelect__value-container) {
        padding-left: 10px;
      }
    }

    :global(.gnSelect__control) {
      background-color: $gl-select-primary-background-color;
      border-radius: $gl-select-primary-border-radius;
      border: $gl-select-primary-border;
    }

    :global(.gnSelect__menu) {
      background-color: $gl-select-primary-background-color;
      border-radius: $gl-select-primary-border-radius;
      padding: 8px 0;
    }

    :global(.gnSelect__indicator),
    :global(.gnSelect__single-value) {
      color: $gl-select-primary-color;
    }

    :global(.gnSelect__placeholder) {
      color: $gl-input-placeholder-color;
      font-weight: $gl-font-weight-regular !important;
    }

    :global(.gnSelect__control.gnSelect__control--is-focused) {
      color: $gl-select-primary-color;
    }

    :global(.gnSelect__control.gnSelect__control--menu-is-open) .indicatorIcon {
      transform: rotate(180deg);
    }

    :global(.gnSelect__option) {
      border: none;
    }

    :global(.gnSelect__option.gnSelect__option--is-focused),
    :global(.gnSelect__option.gnSelect__option--is-selected) {
      background-color: $gl-select-primary-active-background-color;
    }
  }

  &.light {
    font-weight: $gl-font-weight-regular !important;
    @include font-size($gl-font-size-xs);

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.withIcon {
      align-items: center;
      background-color: transparent;
      border-radius: $gl-select-primary-border-radius;
      border: $gl-select-primary-border;
      display: flex;

      :global(.gnSelect__control) {
        background: none;
        border: none;
      }

      :global(.gnSelect__value-container) {
        padding-left: 10px;
      }
    }

    :global(.gnSelect__control) {
      background-color: transparent;
      border-radius: $gl-select-primary-border-radius;
      border: none;
    }

    :global(.gnSelect__menu) {
      background-color: $gl-select-primary-background-color;
      border-radius: $gl-select-primary-border-radius;
      padding: 8px 0;
    }

    :global(.gnSelect__indicator),
    :global(.gnSelect__single-value) {
      color: $gl-select-primary-color;
    }

    :global(.gnSelect__placeholder) {
      color: $gl-input-placeholder-color;
      font-weight: $gl-font-weight-regular !important;
    }

    :global(.gnSelect__control.gnSelect__control--is-focused) {
      color: $gl-select-primary-color;
    }

    :global(.gnSelect__control.gnSelect__control--menu-is-open) .indicatorIcon {
      transform: rotate(180deg);
    }

    :global(.gnSelect__option) {
      border: none;
    }

    :global(.gnSelect__option.gnSelect__option--is-focused),
    :global(.gnSelect__option.gnSelect__option--is-selected) {
      background-color: $gl-select-primary-active-background-color;
    }
  }

  &.secondary {
    &.withIcon {
      align-items: center;
      background-color: $clr-gray-lt;
      border-radius: $gl-select-secondary-border-radius;
      display: flex;
    }

    :global(.gnSelect__control) {
      background-color: $gl-select-secondary-background-color;
      border-radius: $gl-select-secondary-border-radius;
      border: $gl-select-secondary-border;
    }

    :global(.gnSelect__menu) {
      background-color: $gl-select-secondary-background-color;
      border-radius: $gl-select-secondary-border-radius;
    }

    :global(.gnSelect__indicator),
    :global(.gnSelect__placeholder),
    :global(.gnSelect__single-value) {
      color: $gl-select-secondary-color;
    }

    :global(.gnSelect__control.gnSelect__control--is-focused) {
      color: $gl-select-secondary-color;
    }

    :global(.gnSelect__option.gnSelect__option--is-focused),
    :global(.gnSelect__option.gnSelect__option--is-selected) {
      background-color: $gl-select-secondary-color;
      color: $clr-white;
    }
  }
}

.selectIconContainer {
  align-items: center;
  align-self: stretch;
  color: #585858;
  display: flex;
  justify-content: center;
  padding-left: 12px;

  &.disabled {
    cursor: not-allowed;
  }
}

.select {
  :global(.gnSelect__indicator-separator) {
    display: none;
  }

  :global(.gnSelect__control) {
    box-shadow: none;
    min-height: $gl-button-height-lg;
  }

  :global(.gnSelect__menu) {
    border: 1px solid #c4c4c4;
    box-shadow: none;
    margin: 8px 0 0;
    overflow: hidden;
  }

  :global(.gnSelect__menu-list) {
    padding-bottom: 0;
    padding-top: 0;
  }

  :global(.gnSelect__option) {
    align-items: center;
    border-bottom: 1px solid $clr-gray;
    color: $clr-black;
    cursor: pointer;
    display: flex;
    padding: $gl-select-padding-y $gl-select-padding-x;

    &:last-of-type {
      border-bottom: none;
    }
  }

  :global(.gnSelect__single-value) {
    align-items: center;
    display: flex;
    margin: 0;
  }

  :global(.gnSelect__value-container) {
    padding-left: $gl-select-padding-x;
  }
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;
  margin: -2px 5px 0px 0px;
}

.selectButton {
  height: 42px;
  border-radius: $gl-input-border-radius;
  border-color: $clr-black-high;

  &.showIndicator {
    justify-content: space-between;
  }
}

.selectButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectButtonOptions {
  position: absolute;
  width: max-content;
  top: 52px;
  right: 0;
  z-index: 99999;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;

  background-color: $clr-white;
  border: $gl-input-border;
  border-radius: $gl-input-border-radius;
}

.selectButtonIcon {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.selectButtonItem {
  @include font-size($gl-font-size-xs);
  font-weight: $gl-font-weight-regular;
  justify-content: flex-start;
  line-height: 100%;
  padding: 0 24px 0 8px;
  min-height: 42px;
  width: 100%;

  &:hover {
    background-color: $clr-primary-50;
  }

  &.noIcon {
    padding: 0 24px;
  }
}
