@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.appSaveInfo {
  display: flex;
  gap: 4px;
}

.deleted {
  font-style: italic;
}

.pill {
  min-width: auto;
}
