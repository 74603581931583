@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

// fonts
@mixin font-size($size: 16, $important: false) {
  @if ($important) {
    font-size: strip-unit(math.div($size, 16)) + rem !important;
  } @else {
    font-size: strip-unit(math.div($size, 16)) + rem;
  }
}

// breakpoints
@mixin tablet {
  @media (max-width: $gl-breakpoint-tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $gl-breakpoint-mobile) {
    @content;
  }
}

@mixin phone {
  @media (max-width: $gl-breakpoint-phone) {
    @content;
  }
}

// components
@mixin popup($width: auto) {
  background-color: $clr-white;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  max-width: 90vw;
  overflow: hidden;
  padding: 0;
  width: $width;
  z-index: $gl-z-index-popup;
}

@mixin styled-scrollbar($margin-block: 0) {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    margin-block: $margin-block;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #ccc;
  }
}

@mixin hidden-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}
