@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
$left-nav-background-color: #ffffff;
$left-nav-border-radius: 8px;
$left-nav-width-full: 220px;
$left-nav-max-width: 300px;
$left-nav-width-collapsed: 62px;

$left-nav-item-padding-full: 9px;
$left-nav-item-padding-collapsed: 9px;
$left-nav-item-size: 32px;
$left-nav-item-spacing: 4px;

$left-nav-toggle-border-radius: 7px;
$left-nav-toggle-height: 41px;
$left-nav-toggle-offset-x: -11px;
$left-nav-toggle-width: 24px;

.container {
  background-color: $left-nav-background-color;
  border-right: 1px solid $clr-gray-divider;
  overflow: hidden;
  position: relative;
  min-width: $left-nav-width-full;
  max-width: $left-nav-max-width;
  margin-left: 16px;
  margin-bottom: 16px;
  border-radius: 16px 0 0 16px;
  box-shadow: 3px 2px 16px 0px rgba(30, 73, 110, 0.07);
  display: flex;
  flex-direction: column;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: $gl-z-index-left-nav;
    border-radius: 0;
    width: 75%;
  }
}

.header {
  align-items: center;
  background-color: $left-nav-background-color;
  display: flex;
  padding: 24px 10px 24px 24px;
}

.headerIcon {
  flex-shrink: 0;
}

.headerTitle {
  font-size: $gl-font-size-overline;
  font-weight: $gl-font-weight-regular;
  line-height: 1;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orgName {
  font-size: $gl-font-size-m;
  font-weight: $gl-font-weight-bold;
  line-height: 24px;
  color: $clr-primary-500;
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 0 10px 10px 10px;

  &.account {
    padding: 0 10px;
  }

  &.main {
    margin-bottom: 0.5rem;
  }
}

.navigationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    .navigationItemButton {
      background-color: #f2f0ee;
      border-radius: 8px;
    }
    .disabled {
      background-color: rgba(242, 240, 238, 0.6) !important;
    }
  }

  &.active {
    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      .navigationItemButton {
        display: flex;
      }
    }

    .navigationItemButton {
      background-color: $clr-gray-light;
      color: $clr-black-high;
      border-radius: 8px;
    }
    .disabled {
      background-color: #edeceb !important;
    }

    &.navigationItemIcon {
      color: $clr-black-high;
    }

    .navigationItemLabel {
      opacity: 1;
    }
  }
}

.navigationItemButton {
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  color: $clr-black;
  cursor: pointer;
  display: flex;
  padding: $left-nav-item-padding-full;
  text-decoration: none;
  width: 100%;
  min-height: 40px;

  &:focus,
  &:hover,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    border-radius: 8px;
    background-color: $clr-background-light;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.navigationItemIcon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  color: $clr-primary-500;
  min-height: 23px;
  min-width: 23px;

  &.disabled {
    color: rgba(26, 16, 11, 0.6);
  }
}

.navigationItemLabel {
  @include font-size($gl-font-size-s);
  font-weight: $gl-font-weight-regular;
  opacity: 0.75;
  line-height: 1;
  margin-left: 15px;

  &.disabled {
    color: rgba(26, 16, 11, 0.6);
  }
}

.toggle {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  margin-right: 4px;
  outline: none;
  color: $clr-background-light;

  &:hover {
    color: $clr-gray-divider;
  }
}

.toggleIconCollapsed {
  transform: scale(-1, 1);
}

.container.collapsed {
  min-width: $left-nav-width-collapsed;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: none;
  }

  .header {
    justify-content: center;
    padding: 24px 0;
  }

  .headerTitle,
  .orgName,
  .navigationItemLabel {
    display: none;
  }

  .navigationItemButton {
    padding: $left-nav-item-padding-collapsed;
  }

  .toggle {
    margin: 0;
  }
}

.togglePhoneContainer {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    display: block;
    position: relative;
  }
}

.togglePhone {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    align-items: center !important;
    background-color: $clr-black-high !important;
    opacity: 0.8;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: flex !important;
    padding: 12.5px 5px 12.5px 9px !important;
    position: fixed;
    top: $gl-webflow-phone-header-height + 55px;
    z-index: $gl-z-index-left-nav-button;

    &.cartLoaded {
      top: $gl-webflow-phone-header-height + 98px;
    }
  }
}

.togglePhoneChevron {
  color: $clr-white;
  flex-shrink: 0;
}

.overlay {
  display: none;

  @media (max-width: $gl-breakpoint-webflow-phone-w) {
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $gl-z-index-left-nav;
  }
}
.menuDivider {
  height: 1px;
  background-color: $clr-gray-divider;
  margin: 0 0.5rem 0.5rem;
}

.userTab {
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 0.5rem;
  background-color: $clr-white;

  @media (max-height: 400px) {
    position: sticky;
  }
}

.navigationContainer {
  flex: 1;
}

.tooltipContainer {
  width: 100%;
}
