@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.paymentMethodsContainer {
  @include font-size($gl-font-size-xs);
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}

.paymentMethodsLabel {
  color: $clr-black;
  margin: 24px 0 6px;
}

.paymentMethodsDigicert {
  margin-top: -10px;
}

.paymentMethodsMoneyBackGuarantee {
  color: $clr-black;
  margin-bottom: -4px;
}

.paymentMethodsMoneyBackGuaranteeNote {
  @include font-size($gl-font-size-xs);
  align-items: center;
  line-height: 150%;
  margin-top: -6px;
  max-width: 180px;
}

.paymentIcons {
  margin: 0 auto;
}

.moneyBack {
  margin: 10px auto;
}
