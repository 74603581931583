@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.modal {
  display: flex;
  flex-direction: column;
  max-height: 700px !important;
  max-width: 850px !important;
  width: 100%;
  height: 100%;

  &.small {
    max-height: 350px !important;
    max-width: 750px !important;
  }

  @media (max-width: 1000px) {
    border-radius: 0;
    max-height: none !important;
  }
}

.modalContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 0 0;
  width: 100%;
  overflow-y: auto;
  max-height: 450px;

  &.small {
    max-height: 400px !important;
  }
}

.formContent {
  width: 100%;
  height: max-content;
  padding: 20px 20px 0 0;
  position: absolute;
  row-gap: 16px;

  &.advanced {
    padding: 0;
  }
}

.formContentParagraph {
  margin: 0;
  line-height: 1.5;
}

.pluginInfo {
  @include font-size($gl-font-size-base, true);
  line-height: 1.5;
}

.input {
  width: 100%;
}

.subsectionInput {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.value {
  font-weight: $gl-font-weight-bold;
}

.infoLink {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
}

.enableToggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px !important;
}

.switch {
  align-items: center;
  display: flex;
}

.switchLabel {
  @include font-size($gl-font-size-xs);
  font-weight: $gl-font-weight-medium;
  line-height: 1;
  margin-right: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  position: relative;
}

.footer {
  background: white;
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.backupItemContainer {
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 10px 16px 16px;
  }
}

.emptyBackupContainer {
  padding: 30px 24px;
}

.leftContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 13px;

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    flex: 0;
    padding-right: 0;
  }
}

.backupItemTitleDate {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  word-break: break-all;

  @include phone {
    gap: 4px;
  }
}

.backupItemTitle {
  @include font-size($gl-font-size-l);
  align-items: center;
  display: inline-flex;
  font-weight: $gl-font-weight-bold;
  gap: 5px;
  word-break: break-word;

  @include mobile {
    @include font-size($gl-font-size-m);
  }

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    @include font-size($gl-font-size-s);
    line-height: 1.7;
  }
}

.backupItemButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;

  @include phone {
    flex-direction: row;
    gap: 8px;
  }
}

.backupItemDate {
  @include font-size($gl-font-size-2xs);
  align-items: center;
  color: $gl-input-placeholder-color;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-start;
  line-height: 1.2;
  margin: 4px 4px 0 0;
  word-break: break-word;
}

.backupDetails {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.restoreButton {
  @include font-size($gl-font-size-xs);
  height: 32px;
  gap: 10px;
  padding: 0 9px;
  width: fit-content;

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    height: 22px;
    width: auto;
  }
}

.icon {
  padding: 0;
}

.editIcon {
  color: $clr-primary;
  margin-left: 15px;
  padding: 0;
}

.subtitleIcon {
  margin: 0 2px;
}

.saveAndEditIcons {
  display: flex;
  gap: 13px;
}

.loader {
  margin: auto;
}

.deleted {
  font-style: italic;
}

.fetchingBackups {
  margin: 0 auto;
}

.pill {
  margin: auto;
  min-width: fit-content !important;
}
