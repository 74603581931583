@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.toastContentContainer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  font-family: $gl-font-family-base;

  @media (max-width: $gl-accordion-max-width) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: $gl-toast-container-width-phone) {
    align-items: flex-start;
  }
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 8px;
  min-width: 150px;

  @media (max-width: 480px) {
    flex-direction: row;
    width: 100%;

    padding: 5px 0;

    &.single {
      width: 50%;
      margin: 0 auto;
    }
  }
}

.message {
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: $gl-font-weight-bold;
  margin-left: 8px;
  color: #212529;

  @media (max-width: 480px) {
    margin: 0 auto;
    text-align: center;
  }
}

.note {
  @include font-size($gl-font-size-xs);
  font-weight: $gl-font-weight-regular;
}

.toastButton {
  @media screen and (max-width: $gl-toast-container-width-phone) {
    height: 35px;
    width: 100%;
  }
}

.toastButton {
  min-width: 160px;

  @media (max-width: $gl-toast-container-width-phone) {
    min-width: 0;
  }
}
