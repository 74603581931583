@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.infoPopup {
  @include font-size($gl-font-size-xs);
  background: $clr-white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  line-height: 1.5;
  max-width: 330px;
  pointer-events: none;
  z-index: 3147483646;
  visibility: hidden;

  @media (max-width: $gl-breakpoint-webflow-phone) {
    max-width: 264px;
  }

  @media (hover: none), (pointer: coarse) {
    &.visible {
      visibility: visible !important;
    }
  }
}

.infoPopupContainer:hover .infoPopup {
  visibility: visible;

  @media (hover: none), (pointer: coarse) {
    visibility: hidden;
  }
}

.infoPopupArrow {
  position: absolute;
  width: 10px;
  height: 10px;

  &:after {
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    content: " ";
    height: 10px;
    position: absolute;
    transform: rotate(225deg);
    width: 10px;
  }
}

[data-popper-placement="top"] .infoPopupArrow {
  bottom: -5px;

  &:after {
    transform: rotate(225deg);
  }
}

[data-popper-placement="bottom"] .infoPopupArrow {
  top: -5px;

  &:after {
    transform: rotate(45deg);
  }
}

[data-popper-placement="left"] .infoPopupArrow {
  right: -5px;

  &.reactComponent {
    right: 0px;
  }

  &:after {
    transform: rotate(135deg);
  }
}

[data-popper-placement="right"] .infoPopupArrow {
  left: -5px;

  &.reactComponent {
    left: -10px;
  }

  &:after {
    transform: rotate(315deg);
  }
}

.infoPopupButton {
  background-color: transparent;
  padding: 0;
  width: 13px;
  pointer-events: none;
  display: inline-block;

  &.tooltip {
    width: auto;
  }

  @media (hover: none), (pointer: coarse) {
    pointer-events: auto;
  }
}

.infoPopupContent {
  text-transform: none;
  padding: 15px;
  @include font-size($gl-font-size-base);
  font-weight: $gl-font-weight-regular;
  color: $clr-black-high;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;

  &.toolTip {
    @include font-size($gl-font-size-2xs);
  }
}

.infoPopupOverlay {
  background-color: transparent;
  border: none;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  top: 0;
  left: 0;
  z-index: 3147483647;
  display: none;

  @media (hover: none), (pointer: coarse) {
    display: block;
  }
}
