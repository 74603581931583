@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.costSummaryContainer {
  position: sticky;
  top: -20px;
  width: 100%;
  background-color: $clr-white;

  &.contained {
    padding-top: 30px;
  }
}

.costSummaryContent {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  border-radius: 16px 16px 0 0;

  &.contained {
    background-color: $clr-background-light;
    padding: 32px;
  }
}

.costSummaryHeader {
  @include font-size($gl-font-size-xl);
  color: $clr-primary;
  font-family: $gl-font-family-base;
  line-height: 2.25rem;
  margin-bottom: 32px;
  font-weight: $gl-font-weight-bold;

  &.appBuilder {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }
}

.costSummaryItem {
  @include font-size($gl-font-size-xs);
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 10px;
}

.costSummaryItemPrice {
  font-weight: 700;
}

.costSummaryTotal {
  @include font-size($gl-font-size-m);
  color: $clr-primary;
  font-weight: 500;
}

.costSummaryTotalPrice {
  @include font-size($gl-font-size-m);
  color: $clr-primary;
  font-weight: 700;
}

.costSummaryTotalContainer {
  @include font-size($gl-font-size-base);
  align-items: center;
  border-top: 1px solid rgba(26, 16, 11, 0.25);
  color: $clr-primary;
  display: flex;
  height: 64px;
  justify-content: space-between;
  margin: 20px 0 12px;
}

.costSummaryFooter {
  @include font-size($gl-font-size-xs);
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}

.costSummaryStepButton {
  border-radius: 8px;
  color: $clr-black;
  height: 48px;
  gap: 16px;
  // flex: auto;
  position: relative;

  &:hover {
    color: $clr-black;
  }

  &.iconOnly {
    flex: none;
  }

  &.checkoutButton {
    min-width: 227px;
  }
}

.costSummaryButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.costSummaryCheckout {
  color: $clr-black;
  padding: 1px 5px 0;
  font-weight: $gl-font-weight-medium;
  min-width: fit-content;
}

.costSummarySummary {
  padding-top: 4px;
}

.costSummaryItemButton {
  padding: 2px 5px;
  background-color: transparent;

  &:hover {
    color: $clr-green;
  }
}

.costSummaryAddItemButton {
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 7px;
  height: 21px;

  &:hover {
    color: $clr-green;
  }
}

.loader {
  position: absolute;
  right: 16px;
}

.placeholder {
  width: fit-content;
  margin: 0 auto;
  padding-top: 38px;

  .border {
    border: 2px dashed rgba(181, 179, 177, 1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92px;
    width: 338px;
  }

  .center {
    display: flex;
    gap: 12px;
  }

  .label {
    @include font-size($gl-font-size-m);
    color: $clr-primary;
    font-family: $gl-font-family-page-header;
    width: 265px;
    font-weight: $gl-font-weight-bold;
  }

  .icon {
    margin-top: 2px;
  }
}
