// Colors
$clr-black: #1a100b;
$clr-blue-lt: #97b8d5;
$clr-blue: #46a6ff;
// $clr-blue-dk: #1e496e;
$clr-blue-dk: #0e0d08;
$clr-green-dk: #efac00;
$clr-green: #f7c406;
$clr-gray-lt: #f0f0f0;
$clr-gray: #e6e9ed;
$clr-gray-dk: #a3a3a3;
$clr-red: #ef4444;
$clr-red-lt: #f87171;
$clr-yellow: #fbbc04;
$clr-white: #ffffff;

// Primary
$clr-primary-50: #e2dfdd;
$clr-primary-100: #bcc8d4;
$clr-primary-200: #8fa4b7;
$clr-primary-300: #62809a;
$clr-primary-400: #1a100be5;
$clr-primary-500: #1a100b;
$clr-primary-600: #1a4266;
$clr-primary-700: #16395b;
$clr-primary-800: #123151;
$clr-primary-900: #0a213f;

// Error
$clr-error-lightest: #fce7e7;
$clr-error-light: #e53935;
$clr-error-dark: #c62828;

// Info
$clr-info-light: #4299ed26;
$clr-info-dark: #4299ed40;

// Warning
$clr-warning-light: #ffc107;
$clr-warning-dark: #ffa000;

// Success
$clr-success-light: #00e676;
$clr-success-dark: #00c853;

// Black
$clr-black-inactive: #2020208a;
$clr-black-disabled: #949494;
$clr-black-medium: #7d7a77;
$clr-black-high: #1a100b;

// White
$clr-white-inactive: #ffffff8a;
$clr-white-disabled: #ffffff80;
$clr-white-medium: #ffffffb2;
$clr-white-high: #ffffff;

// Background
$clr-background-light: #f2f0ee;
$clr-background-dark: #e7e7e7;
$clr-background-body: #cdcdcd;

// Gray
$clr-gray-divider: #e2dfdd;
$clr-gray-light: #e2dfdd;
$clr-gray-dark: #05092b40;
$clr-light-gray: #faf9fa;

$clr-primary: $clr-blue-dk;
$clr-primary-hover: #132e46;
$clr-secondary: $clr-green;
$clr-secondary-hover: $clr-green-dk;
$clr-success: $clr-green;
$clr-danger: $clr-red;
$clr-danger-hover: $clr-red-lt;
$clr-warning: $clr-yellow;

$clr-border: #c7c7c7;
$clr-border-dark: #1a100b;
$clr-border-hover: #adadad;
$clr-icon-default: #8c97ac;
$clr-icon-android: $clr-black;
$clr-icon-ios: $clr-black;

$clr-border-blue: #4299ed;
$clr-border-pink: #f567f0;
$clr-border-yello: #4299ed;

// Typography
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Questrial&display=swap");
// $gl-font-family-base: DM Sans, Hkgrotesk, system-ui, -apple-system, "Segoe UI",
//   Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$gl-font-family-page-header: Gilroy, system-ui, -apple-system, "Segoe UI",
  Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$gl-font-family-android: Roboto, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji";
$gl-font-family-ios: Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji";
$gl-font-family-fallback: system-ui, -apple-system, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

$gl-font-family-secondary: "Questrial", sans-serif;
$gl-font-family-base: "DM Sans", sans-serif;

$gl-font-size-3xs: 8px;
$gl-font-size-2xs: 12px;
$gl-font-size-xs: 14px;
$gl-font-size-s: 15px;
$gl-font-size-base: 16px;
$gl-font-size-m: 18px;
$gl-font-size-l: 20px;
$gl-font-size-xl: 24px;
$gl-font-size-2xl: 28px;
$gl-font-size-3xl: 30px;
$gl-font-size-4xl: 32px;
$gl-font-size-5xl: 34px;
$gl-font-size-6xl: 48px;

$gl-font-weight-regular: 400;
$gl-font-weight-medium: 500;
$gl-font-weight-semibold: 600;
$gl-font-weight-bold: 700;

$gl-font-size-h1: 6rem;
$gl-font-size-h2: 3.75rem;
$gl-font-size-h3: 3rem;
$gl-font-size-h4: 2.125rem;
$gl-font-size-h5: 1.5rem;
$gl-font-size-h6: 1.25rem;
$gl-font-size-subtitle1: 1rem;
$gl-font-size-subtitle2: 0.875rem;
$gl-font-size-body1: 1rem;
$gl-font-size-body2: 0.875rem;
$gl-font-size-button: 0.875rem;
$gl-font-size-caption: 0.75rem;
$gl-font-size-overline: 0.625rem;

// Inputs
$gl-input-font-weight: $gl-font-weight-regular;

$gl-input-border-color: $clr-gray-dark;
$gl-input-border-color-dark: $clr-border-dark;
$gl-input-border: 1px solid $gl-input-border-color;
$gl-input-border-dark: 1px solid $gl-input-border-color-dark;

$gl-input-active-border-color: $clr-primary;
$gl-input-active-border: 1px solid $gl-input-active-border-color;

$gl-input-border-radius: 8px;
$gl-input-height: 42px;
$gl-input-outline: none;
$gl-input-padding-x: 10px;
$gl-input-padding-y: 0;

$gl-input-background-color: $clr-white;
$gl-input-background-color-dark: #1a100b;
$gl-input-color: $clr-black;
$gl-input-color-dark: $clr-white;
$gl-input-placeholder-color: #6b7177;

$gl-radio-background-color: $clr-white;
$gl-radio-background-color-hover: $clr-gray-lt;
$gl-radio-color: $clr-primary;
$gl-radio-focus-box-shadow: 0 0 0 1pt $gl-input-active-border-color;

$gl-radio-active-background-color: $clr-primary;

$gl-select-padding-x: 8px;
$gl-select-padding-y: 8px;

$gl-select-primary-background-color: $clr-white;
$gl-select-primary-border-radius: $gl-input-border-radius;
$gl-select-primary-border: $gl-input-border;
$gl-select-primary-color: $gl-input-color;
$gl-select-primary-active-background-color: #f2f2f2;

$gl-select-secondary-background-color: $clr-gray-lt;
$gl-select-secondary-border-radius: 6px;
$gl-select-secondary-border: none;
$gl-select-secondary-color: $clr-primary;

$gl-popup-box-shadow: 0 0 0 1pt rgba(0, 0, 0, 0.2);

$gl-slider-active-background-color: $clr-primary;
$gl-slider-background-color: $clr-gray-lt;
$gl-slider-border-radius: 6px;

// Spaces
$gl-spaces-subsection-x: 0.5rem;
$gl-spaces-subsection-y: 1.5rem;
$gl-spaces-subsection-content-y: 1rem;

// Z-indexes
$gl-z-index-1: 10;
$gl-z-index-max: 2147483647;

$gl-z-index-top-bar: 1;
$gl-z-index-popup: 1000000;
$gl-z-index-overlay: 10000;
$gl-z-index-left-nav-button: $gl-z-index-overlay - 1;

$gl-z-index-toast: $gl-z-index-max - 3 !important;
$gl-z-index-modal: 2000000000;
$gl-z-index-left-nav: $gl-z-index-max - 1;
$gl-z-index-groove: $gl-z-index-max - 10;

// Components
$gl-breakpoint-webflow-phone: 479px;
$gl-breakpoint-webflow-phone-w: 630px;
$gl-breakpoint-webflow-mobile: 991px;
$gl-breakpoint-webflow-desktop: 1440px;

$gl-breakpoint-phone: $gl-breakpoint-webflow-phone;
$gl-breakpoint-mobile: $gl-breakpoint-webflow-mobile;
$gl-breakpoint-tablet: 1600px;

$gl-webflow-desktop-header-height: 80px;
$gl-webflow-mobile-header-height: 60px;
$gl-webflow-phone-header-height: 60px;

$gl-org-section-max-width: 1124px;
$gl-accordion-max-width: 780px;
$gl-subsection-max-width: 780px;

$gl-phone-height: 600px;
$gl-phone-width: 280px;
$gl-tablet-height: 500px;
$gl-tablet-width: 700px;

// Buttons
$gl-button-height-lg: 45px;
$gl-button-width-wide: 160px;

//Toast
$gl-toast-container-width-phone: 480px;
